var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "popup_wrap popup_wrap_pdf",
        staticStyle: { width: "1000px" },
      },
      [
        _c(
          "button",
          {
            staticClass: "layer_close",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v("close")]
        ),
        _c("div", { staticClass: "popup_cont" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("B/L Invoice Freight"),
          ]),
          _c("div", { staticClass: "content_box" }, [
            _c("div", { staticClass: "clearfix" }, [
              _c("div", { staticClass: "float_left col_3" }, [
                _c("table", { staticClass: "tbl_list font_sm gridtype" }, [
                  _vm._m(0),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.004")))]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.items, function (vo, idx) {
                      return _c("tr", { key: "tbl_" + idx }, [
                        _c("td", [_vm._v(_vm._s(vo.invIssNo))]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "float_left text_center col_7 pl20",
                  staticStyle: { "max-height": "500px", "overflow-y": "auto" },
                },
                [
                  _c("vue-pdf-app", {
                    staticStyle: { height: "300px" },
                    attrs: {
                      pdf: _vm.pdf.pdfUrl,
                      config: _vm.pdfConfig.config,
                      "id-config": _vm.pdfConfig.idConfig,
                      theme: "light",
                    },
                  }),
                  _c("div", { staticClass: "paging print" }, [
                    _c("span", { staticClass: "num" }, [
                      _c("input", {
                        staticClass: "pageNumber",
                        attrs: {
                          type: "number",
                          id: _vm.pdfConfig.idConfig.pageNumber,
                          maxlength: "2",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "pageNumber",
                          attrs: { id: _vm.pdfConfig.idConfig.numPages },
                        },
                        [_vm._v("-")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.fnDownlaod()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.101")))]
            ),
            _c(
              "a",
              {
                staticClass: "button blue lg",
                attrs: { id: _vm.pdfConfig.idConfig.print },
              },
              [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.102")))]
            ),
            _c(
              "a",
              {
                staticClass: "button blue lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openSendEmailPopup()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.REPORT_PDF.103")))]
            ),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      [
        _c(
          "win-layer-pop",
          { staticClass: "pdf_send_email" },
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  attrs: { "parent-info": _vm.sendEmailParam },
                  on: { close: _vm.closeSendEmailPopup },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "100%" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }